import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  signal,
  WritableSignal,
  ElementRef,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, Subscription, forkJoin } from 'rxjs';
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Mousewheel,
  A11y,
  SwiperOptions,
} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay]);

import { CampaignDoctorService } from './../../../../../proxy/services/campaign-doctor.service';
import {
  CampaignDto,
  CampaignDoctorDto,
} from './../../../../../proxy/dto-models/models';
import { CampaignService } from './../../../../../proxy/services/campaign.service';
import { AuthService } from './../../../../../shared/services/auth.service';
import { register } from 'swiper/element/bundle';
@Component({
  selector: 'app-emergency-doctors',
  templateUrl: './emergency-doctors.component.html',
  styleUrls: ['./emergency-doctors.component.scss'],
})
export class EmergencyDoctorsComponent implements OnInit, OnDestroy {
  doctorList!: any[];
  isLoading = false;
  liveDoctorList!: any[];
  campaigns: CampaignDto[] = [];
  campaignDetails: Array<{
    campaign: CampaignDto;
    doctors: CampaignDoctorDto[];
  }> = [];
  filterModel = {
    offset: 0,
    limit: 0,
    pageNo: 1,
    pageSize: 2,
    sortBy: 'name',
    sortOrder: 'asc',
    isDesc: false,
  };
  private subscription: Subscription | null = null;
  public picUrl = `${environment.apis.default.url}/`;
  loadingState: WritableSignal<{ skelton: boolean }> = signal({
    skelton: false,
  });
  constructor(
    private campaignService: CampaignService,
    private campaignDoctorService: CampaignDoctorService
  ) {}

  ngOnInit(): void {
    this.getCampaigns();
  }

  public config: SwiperOptions = {
    modules: [Navigation, Pagination, A11y, Mousewheel],
    autoHeight: true,
    spaceBetween: 20,
    navigation: true,
    pagination: { clickable: true, dynamicBullets: true },

    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    centeredSlides: true,
    breakpoints: {
      400: {
        slidesPerView: 'auto',
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2,
        centeredSlides: false,
      },
      1024: {
        slidesPerView: 3,
        centeredSlides: false,
      },
    },
  };
  getCampaigns() {
    this.loadingState.set({ skelton: true });

    this.subscription = this.campaignService
      .getList()
      .pipe(
        map((campaigns: CampaignDto[]) => campaigns.filter((c) => c.isActive))
      )
      .subscribe({
        next: (activeCampaigns: CampaignDto[]) => {
          this.campaigns = activeCampaigns;
          this.fetchDoctorsForCampaigns(activeCampaigns);
        },
        error: () => this.loadingState.set({ skelton: false }),
      });
  }

  fetchDoctorsForCampaigns(campaigns: CampaignDto[]) {
    const requests = campaigns.map((campaign) =>
      this.campaignDoctorService
        .getCampaignDoctorListByCampaignId(campaign.id ?? 0)
        .pipe(
          map((doctors: any[]) =>
            doctors.map((doctor) => ({
              ...doctor,
              profilePicUrl: this.generateProfilePicUrl(doctor.profilePic),
            }))
          ),
          map((doctors) => ({ campaign, doctors }))
        )
    );

    forkJoin(requests).subscribe({
      next: (details) => {
        this.campaignDetails = details;
        this.loadingState.set({ skelton: false });
      },
      error: () => this.loadingState.set({ skelton: false }),
    });
  }

  public generateProfilePicUrl(path: string): string {
    return this.picUrl + (path || '').replace(/wwwroot/gi, '');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
