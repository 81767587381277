import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/proxy/services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TosterService } from 'src/app/shared/services/toster.service';

@Component({
  selector: 'app-appointment-success',

  templateUrl: './appointment-success.component.html',
  styleUrl: './appointment-success.component.scss',
})
export class AppointmentSuccessComponent {
  appCode: any;
  userType: string = '';
  isLoading: boolean = false;
  message: string = '';
  showTemplate: boolean = false;
  constructor(
    private appointmentService: AppointmentService,
    private tosterService: TosterService,
    private AuthService: AuthService,
    private Router: Router
  ) {}
  ngOnInit() {
    this.userType = this.AuthService.authInfo()?.userType;

    // Safely handle invalid or missing JSON in localStorage
    let patientAppointmentCode = localStorage.getItem('patientAppointmentCode');

    
    try {
      this.appCode = patientAppointmentCode
        ? JSON.parse(patientAppointmentCode)
        : null;
    } catch (error) {
      console.error('Invalid JSON in patientAppointmentCode:', error);
      this.appCode = null;
    }

    this.isLoading = true;

    if (!this.userType) {
      this.showTemplate = false;
      this.Router.navigate(['/']);
      return;
    }

    this.showTemplate = true;

    if (this.appCode) {
      this.appointmentService
        .updateAppointmentStatusAfterPayment(this.appCode, 1)
        .subscribe(
          (st) => {
            this.isLoading = false;
            this.message = 'Your appointment is confirmed!';
            console.log('remove');
            localStorage.removeItem('patientAppointmentCode');
          },
          (error) => {
            this.isLoading = false;
            this.tosterService.customToast(
              'Error updating appointment status',
              'error'
            );
          }
        );
    } else {
      this.Router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    if (!this.userType || !this.showTemplate) {
      this.tosterService.customToast(
        'You are not authorized to visit this page',
        'error'
      );
      this.Router.navigate(['/']);
    }
  }
}
