<div id="emergency" class="bg-gray-50 py-10 lg:py-12 xl:py-14 2xl:py-20 lg:h-[90vh] 2xl:h-fit">
  <div class="max-w-[1400px] mx-auto p-4">
    <div *ngIf="loadingState().skelton">
      <app-text-skelton></app-text-skelton>
    </div>
    <ng-container *ngIf="!loadingState().skelton">
      <div *ngFor="let detail of campaignDetails; let i = index">
        <div class="mb-10">
          <div class="py-5 lg:py-8 flex w-full lg:flex-row flex-col-reverse gap-5 items-center lg:justify-between">
            <div class="text-center lg:text-left">
              <p class="font-Roboto text-secondary">{{ detail.campaign.title }}</p>
              <h2
                class="text-[22px] text-primary md:text-[26px] lg:text-[34px] font-poppins font-semibold text-blue-gray-600">
                {{ detail.campaign.subTitle }}
              </h2>
            </div>
            <img class="max-w-[180px] lg:max-w-[200px]" src="assets/other/1click-c.svg" alt="png" />
          </div>




          <swiper-container appSlider [config]="config" init="false" class="w-full">
            @for (doctorDetails of detail.doctors; track $index) {
            <swiper-slide>
              <app-emergency-doctor-card [doctorDetails]="doctorDetails"></app-emergency-doctor-card>
            </swiper-slide>
            }
          </swiper-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>