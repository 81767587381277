@if (bookingInfo && (bookingInfo.totalAppointmentFee == 0 ||
bookingInfo.totalAppointmentFee)) { @if (bookingInfo.totalAppointmentFee !== 0)
{
<div class="flex p-2 box-border mx-auto rounded-md my-5 border-[1px]">
  <input
    #coupon
    class="flex-1 focus:outline-none text-[20px] font-Roboto placeholder:text-[14px] w-full"
    type="text"
    placeholder=" Apply your coupon code."
  />
  <button
    [disabled]="
      bookingInfo.totalAppointmentFee < 10 ||
      !coupon.value ||
      disableApplyButton
    "
    class="btn-primary"
    (click)="applyCoupon(coupon.value)"
  >
    Apply
  </button>
</div>
}

<div class="text-center items-center w-full">
  <ul>
    @if (bookingInfo.totalAppointmentFee !== 0) {
    <li class="flex justify-between text-[14px] font-poppins text-gray-500">
      <span>Consultation fee:</span>
      <span> {{ previousTotalFee }} tk. </span>
    </li>
    <li class="flex justify-between text-[14px] font-poppins text-gray-500">
      <span>Discount:</span>

      @if (discount) {
      <span class="text-red-500"
        >-{{ discount.discountAmount }}
        {{ discount.discountAmountIn === 1 ? "tk." : "%" }}</span
      >
      }@else{
      <span>0tk.</span>
      }
    </li>
    <hr class="mt-2" />
    }
    <li
      class="flex mt-2 justify-between text-[24px] font-poppinsBold text-secondary"
    >
      <span>Total <span class="text-[12px]">(inc. VAT):</span> </span>
      <span> {{ bookingInfo.totalAppointmentFee }} tk.</span>
    </li>
  </ul>
</div>
}@else { No Booking details found! }
