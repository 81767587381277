<div class="max-h-full flex relative overflow-hidden w-full bg-white">


  <swiper-container class="mySwiper lg:h-[500px] aspect-[4/1.5] sm:aspect-[4/2] lg:aspect-auto" pagination="true"
    pagination-clickable="true" navigation="true" space-between="30" centered-slides="true" autoplay-delay="4000"
    autoplay-duration="1000" loop="true" autoplay-disable-on-interaction="false">

    <swiper-slide class="swiper-slide " (click)="onClickService()">
      <img class="w-full h-full" src="/assets/banner/b3.jpg" alt="banner" />
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <img class="w-full h-full" src="/assets/banner/b2.jpg" alt="banner" />
    </swiper-slide>
    <swiper-slide class="swiper-slide ">
      <img class="w-full h-full" src="/assets/banner/b1.jpg" alt="banner" />
    </swiper-slide>
    <div class="autoplay-progress" slot="container-end">
      <svg viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="20"></circle>
      </svg>
      <span></span>
    </div>
  </swiper-container>
</div>